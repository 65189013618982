<template>
  <div class="pageContainer">
    <el-row style="margin-top: 20px;">
      当前版本号：
      <el-input v-model="versionInfo.label" size="small" placeholder="版本号" style="max-width: 200px; margin: 0 10px;" />
      <el-button @click="dictSave" type="primary" plain size="small">保存</el-button>
    </el-row>

    <el-table border stripe :data="tableData" style="width: 100%; margin-top: 20px; color: #000">
      <el-table-column prop="name" label="表名称" width="200"></el-table-column>
      <el-table-column label="操作" min-width="150">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-popconfirm title="确定进行初始化同步吗？" @confirm="onConfirm(scope.row)">
              <el-tag slot="reference" type="danger" size="mini" style="margin-left: 10px;">同步</el-tag>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>
<script>
export default {
  data() {
    return {
      fullscreenLoading: false,
      versionInfo: {
        type: 'version',
      },
      tableData: []
    };
  },
  mounted() {
    this.getVersion()

    let pmid = sessionStorage.getItem("pmid")
    console.log(38, pmid)
    switch (pmid) {
      case '5':
        this.tableData = [
          { name: '组织架构', api: 'api/njcj_init_office' },
          { name: '学生表(只同步批次结束时间今天以前)', api: 'api/njcj_import_student' },
          { name: '教师表', api: 'api/njcj_import_teachers' },
          { name: '字典表', api: 'api/njcj_sys_dict_save' },
          { name: '行业表', api: 'api/njcj_initIndustry' },
          { name: '职位类别', api: 'api/njcj_initJobType' },
          { name: '班级相册', api: 'api/njcj_initClassImg' },
        ]
        break;
      case '7':
        this.tableData = [
          { name: '组织架构', api: 'api/wxqc_init_office' },
          { name: '学生表', api: 'api/wxqc_import_student' },
          { name: '教师表', api: 'api/wxqc_import_teachers' },
          { name: '字典表', api: 'api/wxqc_sys_dict_save' },
          { name: '行业表', api: 'api/wxqc_initIndustry' },
          { name: '职位类别', api: 'api/wxqc_initJobType' },
          { name: '班级相册', api: 'api/wxqc_initClassImg' },
        ]
        break;
      case '8':
        this.tableData = [
          {name: '组织架构', api: 'api/xwzz_init_office'},
          {name: '学生表', api: 'api/xwzz_import_student'},
          {name: '教师表', api: 'api/xwzz_import_teachers'},
          {name: '字典表', api: 'api/xwzz_sys_dict_save'},
          {name: '行业表', api: 'api/xwzz_initIndustry'},
          {name: '职位类别', api: 'api/xwzz_initJobType'},
          {name: '班级相册', api: 'api/xwzz_initClassImg'},
        ]
        break;
      case '214':
        this.tableData = [
          { name: '组织架构', api: 'api/njsy_init_office' },
          { name: '学生表', api: 'api/njsy_import_student' },
          { name: '教师表', api: 'api/njsy_import_teachers' },
          { name: '字典表', api: 'api/njsy_sys_dict_save' },
          { name: '行业表', api: 'api/njsy_initIndustry' },
          { name: '企业表', api: 'api/njsy_initEnterprise' },
          { name: '职位类别', api: 'api/njsy_initJobType' },
          { name: '班级相册', api: 'api/njsy_initClassImg' },
        ]
        break;
      default:
        this.tableData = []
        break;
    }

  },
  methods: {
    getVersion() {
      return new Promise(resolve => {
        this.$http.post('api/sys_dict_list', { dictType: ['version'] }).then(res => {
          if (res.data && res.data.length > 0) {
            this.versionInfo = res.data[0]
          }
          resolve()
        })
      })
    },
    dictSave() {
      console.log(111111111, this.versionInfo)
      this.$http.post('api/dict_save', this.versionInfo).then(res => {
        if (res.data.code == 200) {
          this.$message.success('保存成功！')
          this.getVersion()
        }
      })
    },
    onConfirm(e) {
      let apiUrl = e.api
      this.fullscreenLoading = true;
      this.$http.post(apiUrl).then((res) => {
        this.fullscreenLoading = false;
        this.$message.success(res.data.msg);
      });
    },
  },
};
</script>
<style type="text/less" lang="less">
.btns {
  .el-button {
    min-width: 200px;
    margin: 20px 0 0 0;
  }
}
</style>
